<template>
  <div class="testAnnal-all">
    <edp-header noSearch="true"></edp-header>

    <div class="testAnnal-content">
      <div class="testAnnal" v-if="pageShow">
        <img src="@/src/assets/test/testAnn.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "annualReport",
  data() {
    return {
      pageShow: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.pageShow = true;
    }, 500);
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.testAnnal-all {
  position: relative;
  display: flex;
  flex-direction: column;
}
.testAnnal {
  width: toPad(400);
  margin: 0 auto;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.testAnnal-content {
  position: relative;
  width: 100%;
  height: calc(100vh - 60px);
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
